import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                    clientId: '962a22f7-4c19-48a6-8e46-faf831fa392e',
                    authority:
                        'https://login.microsoftonline.com/78a6b313-ae8f-4324-ba36-85e7b2bc6f1d',
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
            }
        };
    },
});

export default store;