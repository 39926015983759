<template>
  <div class="flex bg-gray-900 py-1 px-5 h-14">
    <h1 class="text-white text-3xl mt-1">
      <span class="text-orange-400">Invoice</span>Viewer
    </h1>
    <div class="flex-grow" />
    <UserInfo/>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo.vue";
export default {
  name: "Header",
  components: {UserInfo}
}
</script>