<template>
  <button v-if="!account" @click="signIn" class="text-white text-xs bg-gray-800 border-1 border-white py-1 px-4 m-2">Sign in</button>
  <button v-else @click="signOut" class="text-white text-xs bg-gray-800 border-1 border-white py-1 px-4 m-2">Sign out</button>
  <div v-if="account" class="flex mt-2 my-2 mr-5 min-w-100">
    <img v-show="userPhotoFound" class="h-8 w-8 ml-4 rounded-full bg-white" id="userPhoto" src="#" alt="User Photo">
    <div class="text-white text-1xl ml-4 mt-1">{{ account.name }}</div>
  </div>
  <div v-else class="flex my-2 mr-5 min-w-100">
    <div class="text-white text-1xl ml-5 mt-1">Not signed in</div>
  </div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import axios from "axios";
const instance = axios.create({baseURL: 'https://invoiceviewer.backend.hmgbs.com'})
export default {
  name: 'UserInfo',
  data() {
    return {
      account: undefined,
      userPhotoFound: false
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
        this.$store.state.msalConfig,
    );
    this.$emitter.on('renewToken', () => {
      console.log("Renewing all tokens...")
      this.getGraphAccessToken();
      this.getStorageToken();
    })
  },
   mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);

    this.getIsAllowedAccess();
    this.getUserPhoto();
  },
  methods: {
    async signIn() {
      let scopes = [
        'https://graph.microsoft.com/User.ReadBasic.All'
      ]
      await this.$msalInstance
          .loginPopup({scopes: scopes})
          .then(async () => {
            const myAccounts = this.$msalInstance.getAllAccounts();
            this.account = myAccounts[0];
            await this.getGraphAccessToken();
            await this.getStorageToken();
            await this.getIsAllowedAccess();
            await this.getUserPhoto();
            this.$emitter.emit('login', this.account);
          }).catch(error => {
            console.error(`error during authentication: ${error}`);
          });
    },
    async signOut() {
      await this.$msalInstance
          .logoutPopup({})
          .then(() => {
            console.log("logged out")
            localStorage.clear()
            this.account = undefined;
            this.$msalInstance["browserStorage"].clear();
            this.$emitter.emit('logout', 'logging out');
          }).catch(error => {
            console.error(error);
          });
    },
    async getIsAllowedAccess(retry = true) {
      let accessToken = localStorage.getItem("accessToken-storage");
      let vm = this;
      await instance.get('/verifyUser', {
        headers: { Authorization: `Bearer ${accessToken}` }
      }).then(function(response) {
        if (response.status != 200) {
          vm.$emitter.emit('accessDenied');
        }
      }).catch(async function(error) {
        console.log(error)
        if (retry) {
          await vm.getStorageToken();
          await vm.getIsAllowedAccess(false)
          return
        }
        vm.$emitter.emit('accessDenied');
      })
    },
    async getGraphAccessToken(){
      let scopes = [
        'https://graph.microsoft.com/User.ReadBasic.All'
      ]
      await this.getAccessToken(scopes, "graph")
    },
    async getStorageToken(){ // also used for API authentication
      let scopes = [
        'https://storage.azure.com/user_impersonation'
      ]
      await this.getAccessToken(scopes, "storage")
    },
    async getAccessToken(scopes, name){
      let request = {
        scopes: scopes,
        account: this.account
      };

      try {
        const msalInstance = new PublicClientApplication(
            this.$store.state.msalConfig,
        );
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        localStorage.setItem("accessToken-" + name, tokenResponse.accessToken)
        console.log(tokenResponse.accessToken, name)
      } catch (error) {
        console.error( 'Silent token acquisition failed. Using interactive mode' );
        console.log(error)
        let tokenResponse = await this.$msalInstance.acquireTokenPopup(request);
        localStorage.setItem("accessToken-" + name, tokenResponse.accessToken)
        console.log("Access token acquired via interactive auth")
      }
    },
    async getUserPhoto(retry = true) {
      let accessToken = localStorage.getItem("accessToken-graph");
      let vm = this;
      await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { Authorization: 'Bearer ' + accessToken},
      }).then(async function(response) {
        if (response.status === 401 && retry) {
          console.warn("Token expired! Requesting new token.")
          await vm.getGraphAccessToken()
          await vm.getUserPhoto(false)
        } else if (response.status === 200) {
          const pictureBlob = await response.blob();
          document.querySelector('#userPhoto').src = URL.createObjectURL(pictureBlob);
          vm.userPhotoFound = true; // only v-show photo if one is found
        }
      })
    }
  },
};
</script>