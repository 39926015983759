<template>
  <div class="bg-gray-800 h-screen font-sans overflow-y-scroll ">
    <Header/>
    <div v-if="isAccessDenied" class="flex flex-row h-96 justify-center items-center">
      <WelcomePane>Willkommen im <strong>InvoiceViewer</strong>! Dein Unternehmenskonto hat keinen Zugriff auf diese Anwendung!</WelcomePane>
    </div>
    <div v-else-if="isLoggedIn"><InvoiceView/></div>
    <div v-else class="flex flex-row h-96 justify-center items-center">
      <WelcomePane>Willkommen im <strong>InvoiceViewer</strong>! Bitte melde dich mit deinem Unternehmenskonto an, um die Anwendung zu nutzen.</WelcomePane>
    </div>
  </div>
</template>

<script>
import WelcomePane from "@/components/WelcomePane.vue";
import Header from "@/components/Header.vue";
import InvoiceView from "@/components/InvoiceView.vue";

export default {
  created() {
    this.$emitter.on('login', (account) => {
      this.account = account;
    })
    this.$emitter.on('logout', () => {
      this.account = undefined;
    });
    this.$emitter.on('accessDenied', (account) => {
      this.isAccessDenied = true;
    })
  },
  data() {
    return {
      account: undefined,
      isAccessDenied: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.account !== undefined;
    }
  },
  components: {InvoiceView, Header, WelcomePane}
}

</script>