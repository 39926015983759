<template>
  <div class="max-w-xl rounded overflow-hidden shadow-lg bg-gray-700 text-white">
    <div class="px-16 py-14">
      <div class="font-bold text-xl mb-2">InvoiceViewer</div>
      <p class="text-white text-base">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePane"
}
</script>