<template>
  <div class="flex flex-col items-center text-white">
    <div class="flex my-5 text-white w-2/3">
      <div class="w-full mx-2">
        <input class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md mb-3 invalid:bg-red-300" type="number" placeholder="Fakturanummer" v-model="inv_number" @keydown="invalidateForm()"/>
        <input class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md mb-3 invalid:bg-red-300" type="number" placeholder="Kundennummer" v-model="cus_number" @keydown="invalidateForm()"/>
        <input class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md mb-3 invalid:bg-red-300" type="text" placeholder="Kundenname (Teileingabe möglich)" v-model="cus_name" @keydown="invalidateForm()"/>
      </div>
      <div class="w-full mx-3">
        <input class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md mb-3 invalid:bg-red-300" type="number" placeholder="Auftragsnummer" v-model="ord_number" @keydown="invalidateForm()"/>
        <div class="flex">
          <label for="startDate">Zeitraum Beginn:</label>
          <input id="startDate" class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md mb-3 invalid:bg-red-300" type="date" min="2011-01-01" v-model="range.start">
        </div>
        <div class="flex">
          <label for="endDate">Zeitraum Ende:</label>
          <input id="endDate" class="bg-gray-200 flex w-full text-gray-900 px-3 py-3 rounded-md invalid:bg-red-300" type="date" min="2011-01-01" v-model="range.end">
        </div>
      </div>
    </div>
  </div>
  <Button :isLoading="isLoading['search']" :isFailed="isFailed['search']" :errorMessage="errorMessage['search']" label="Suche" @buttonClick="searchValues()" />
  <div v-if="resultData.length>0" class="flex flex-col my-10 items-center text-white">
    <table class="table-fixed mx-16 break-words text-center">
      <tr>
        <th class="bg-orange-500 px-16 py-2">Fakturanummer</th>
        <th class="bg-orange-500 px-16 py-2">Kundennummer</th>
        <th class="bg-orange-500 px-16 py-2">Kundenname</th>
        <th class="bg-orange-500 px-16 py-2">Belegdatum</th>
        <th class="bg-orange-500 px-16 py-2">Auftragsnummern</th>
        <th class="bg-orange-500 px-16 py-2">Datei</th>
      </tr>
      <tr v-for="item in resultData" :key = "item.inv_fakturanummer">
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2"><div class="p-2">{{ item.inv_number }}</div></td>
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2"><div class="p-2">{{ item.customer?.cus_number ?? -1 }}</div></td>
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2"><div class="p-2">{{ item.customer?.cus_name ?? "n/a"}}</div></td>
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2"><div class="p-2">{{ item.inv_date }}</div></td>
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2 h-10"><div class="max-h-32 overflow-y-scroll p-2">{{ item.order_numbers }}</div></td>
        <td class="bg-gray-700 border-gray-600 border-solid border-b-2"><div class="p-2">
          <Button :isLoading="isLoading[item.inv_number]" :isFailed="isFailed[item.inv_number]" :errorMessage="errorMessage[item.inv_number]" label="Öffne PDF" @buttonClick="loadDocument(item.inv_number, item.inv_date)" />
        </div></td>
      </tr>
    </table>
  </div>
  <Button class="mb-10" v-if="resultData.length>0 && !formInvalidated" :isLoading="isLoading['loadMore']" :isFailed="isFailed['loadMore']" :errorMessage="errorMessage['loadMore']" label="Mehr anzeigen" @buttonClick="searchValues(true)" />
</template>

<script>
import axios from 'axios';
import Button from "@/components/Button.vue";

const instance = axios.create({baseURL: 'https://invoiceviewer.backend.hmgbs.com'})
export default {
  data() {
    return {
      isLoading: {},
      isFailed: {},
      errorMessage: {},
      formInvalidated: false,
      entriesLoadedSoFar: 0,
      inv_number: "",
      cus_number: "",
      cus_name: "",
      ord_number: "",
      resultData: [],
      errors: [],
      range: {
        start: new Date('2011-01-01').toISOString().slice(0,10),
        end: new Date().toISOString().slice(0,10)
      }
    };
  },
  methods: {
    async invalidateForm() {
      this.formInvalidated = true
    },
    async loadDocument(inv_number, inv_date, retry = true) {
      this.isLoading[inv_number] = true;
      this.isFailed[inv_number] = false;

      let vm = this;
      let accessToken = localStorage.getItem("accessToken-storage");
      let postData = {};
      postData["filename"] = inv_number + ".pdf";
      postData["year"] = inv_date.toString().substring(0, 4);
      postData["month"] = inv_date.toString().substring(5, 7);

      await instance.post('/document', postData, {
        headers: { Authorization: `Bearer ${accessToken}` },
        responseType: "blob"
      }).then(function(response) {
        const blob = new Blob([response.data], {type: "application/pdf"});
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        vm.isLoading[inv_number] = false;
      }).catch(function(error) {
        console.log(error);
        if (retry) {
          vm.$emitter.emit('renewToken');
          setTimeout(function(){
            vm.loadDocument(inv_number, inv_date, false)
          }, 3000);
          return;
        }
        vm.isLoading[inv_number] = false;
        vm.isFailed[inv_number] = true;
        vm.errorMessage[inv_number] = "Fehler"
      })
    },
    async searchValues(fetchMore = false, retry = true){
      // check if we are already busy
      if (this.isLoading['search']) return

      // Check if we have any filters entered
      if (this.inv_number == "" && this.cus_number == "" && this.cus_name == "" && this.ord_number == "") {
        this.isFailed['search'] = true;
        this.errorMessage['search'] = "Bitte mindestens einen Filter angeben!"
        return
      }

      if (!fetchMore) {
        this.isFailed['search'] = false;
        this.isLoading['search'] = true;
      } else {
        this.isFailed['loadMore'] = false;
        this.isLoading['loadMore'] = true;
      }

      if (!fetchMore) {
        this.entriesLoadedSoFar = 0;
      }

      // dynamically assemble filter parameters
      let postData = {};
      if (fetchMore) {
        postData["offset"] = this.entriesLoadedSoFar;
      }
      postData["include_customer"] = true;
      postData["include_order"] = true;
      postData["inv_date"] = {}
      postData["inv_date"]["start"] = this.range.start;
      postData["inv_date"]["end"] = this.range.end;
      if (this.inv_number != "")  postData["inv_number"] = this.inv_number;
      if (this.cus_number != "")  postData["cus_number"] = this.cus_number;
      if (this.cus_name != "")    postData["cus_name"] = this.cus_name;
      if (this.ord_number != "")  postData["ord_number"] = this.ord_number;

      let vm = this;
      let accessToken = localStorage.getItem("accessToken-storage");
      await instance.post('/invoice', postData,{
        headers: { Authorization: `Bearer ${accessToken}` }
      }).then(function(response) {
        let res = response.data;
        for (let item in res) {
          res[item].order_numbers = res[item].orders.map(order => order.ord_number).join("\n");
        }
        if (!fetchMore) {
          vm.resultData = res;
          vm.formInvalidated = false; // form is fine until changed
        } else {
          vm.resultData.push(...res)
        }

        vm.isLoading['search'] = false;
        vm.isLoading['loadMore'] = false;

        if (res.length === 0) {
          if (!fetchMore) {
            vm.isFailed['search'] = true;
            vm.errorMessage['search'] = "Nichts gefunden"
          } else {
            vm.isFailed['loadMore'] = true;
            vm.errorMessage['loadMore'] = "Vollständig geladen"
          }
        } else {
          vm.entriesLoadedSoFar += 10;
        }
      }).catch(function(error) {
        console.error(error)
        if (retry) {
          vm.$emitter.emit('renewToken');
          setTimeout(async function(){
            vm.isLoading['search'] = false;
            vm.isLoading['loadMore'] = false;
            await vm.searchValues(fetchMore, false)
          }, 2500);
          return;
        }

        vm.isLoading['search'] = false;
        vm.isLoading['loadMore'] = false;
        if (!fetchMore) {
          vm.isFailed['search'] = true;
          vm.errorMessage['search'] = "Fehler";
        } else {
          vm.isFailed['loadMore'] = true;
          vm.errorMessage['loadMore'] = "Fehler";
        }
      })
    },
  },
  components: {Button}
}
</script>