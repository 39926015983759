import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Emitter from 'tiny-emitter';
import store from './store';

// Tailwind CSS
import './tailwind.css'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
library.add(faCircleNotch )
// Create App
const app = createApp(App)
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .mount('#app')