<template>
  <div class="flex flex-col items-center text-white mx-5">
    <button v-if=isLoading class="inline-block rounded-md text-m bg-orange-700 px-14 py-2 hover:bg-orange-600" @click="$emit('buttonClick')">
      <font-awesome-icon class="animate-spin" icon="fa-solid fa-circle-notch" />
      Loading...
    </button>
    <button v-else-if="isFailed" class="inline-block rounded-md text-m bg-red-700 px-14 py-2 hover:bg-red-600" @click="$emit('buttonClick')">{{errorMessage}}</button>
    <button v-else class="inline-block rounded-md text-m bg-orange-700 px-14 py-2 hover:bg-orange-600" @click="$emit('buttonClick')">{{label}}</button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["isLoading", "isFailed", "errorMessage", "label"]
}
</script>

<style scoped>

</style>